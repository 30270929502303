import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['original', 'translation'];

    toggle () {
        if (this.originalTarget.style.display === 'none') {
            this.translationTarget.style.display = 'none';
            this.originalTarget.style.display = 'block';
            this.originalTarget.querySelector('button').focus();
        } else {
            this.translationTarget.style.display = 'block';
            this.originalTarget.style.display = 'none';
            this.translationTarget.querySelector('button').focus();
        }
    }
}
